body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.feature-container{
  display: flex;
  justify-content: space-around;
  padding: 3vw;
  margin: 2vw 0 2vw 0;
  background-color:#f7f9fa;
}

.feature{
  display: flex;
}

.feature-icon{
  font-size: 1.5rem;
}

.feature-icon{
  margin: 0 1vw 0 1vw;
}

.feature-name{
  font-size: 0.8rem;
  color:rgb(79, 79, 79);
  display: flex;
  align-items: center;
}
